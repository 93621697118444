import { AuthState } from "@/models/utils/auth-state.interface";
import { CheckTokenRequest } from "@/models/utils/check-token-request.interface";
import { CheckTokenResponse } from "@/models/utils/check-token-response.interface";
import { LoginRequest } from "@/models/utils/login-request.interface";
import { ResetPasswordRequest } from "@/models/utils/reset-password-request.interface";
import {
  Registration,
  RegistrationComplete,
} from "@/models/utils/user-registration.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "authenticate";

class AuthenticateService extends ApiService {
  login(credentials: LoginRequest) {
    return this.post<LoginRequest, AuthState>(
      `${CONTROLLER}/login`,
      credentials
    );
  }

  logout() {
    return this.getAll<void>(`${CONTROLLER}/logout`);
  }

  register(registration: Registration) {
    return this.post<Registration, void>(
      `${CONTROLLER}/register`,
      registration
    );
  }

  registerComplete(registrationComplete: RegistrationComplete) {
    return this.post<RegistrationComplete, void>(
      `${CONTROLLER}/register/complete`,
      registrationComplete
    );
  }

  checkToken(tokenRequest: CheckTokenRequest) {
    return this.post<CheckTokenRequest, CheckTokenResponse>(
      `${CONTROLLER}/check-token`,
      tokenRequest
    );
  }

  requestConfirmEmail(email: string) {
    return this.post<string, void>(
      `${CONTROLLER}/request-confirm-email`,
      JSON.stringify(email)
    );
  }

  requestPasswordReset(email: string) {
    return this.post<string, void>(
      `${CONTROLLER}/request-password-reset`,
      JSON.stringify(email)
    );
  }

  resetPassword(pwdRequest: ResetPasswordRequest) {
    return this.post<ResetPasswordRequest, void>(
      `${CONTROLLER}/reset-password`,
      pwdRequest
    );
  }

  checkEmail(email: string) {
    return this.getAll<boolean>(`${CONTROLLER}/check-email`, {
      params: {
        email: email,
      },
    });
  }
}

export const authenticate = new AuthenticateService();
