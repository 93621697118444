
import { Component, Vue } from "vue-property-decorator";
import { email, required } from "@/helpers/rules";
import { LoginRequest } from "@/models/utils/login-request.interface";
import { authenticate } from "@/services/api/authenticate.service";
import { orders } from "@/services/api/orders.service";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import CartModule from "@/store/modules/cart.module";
import OverlayModule from "@/store/modules/overlay.module";
import EmailModule from "@/store/modules/email.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { CLIENT } from "@/helpers/roles";
const authModule = getModule(AuthModule, store);
const cartModule = getModule(CartModule, store);
const overlayModule = getModule(OverlayModule, store);
const emailModule = getModule(EmailModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Login extends Vue {
  private valid = true;
  private showAlert = false;
  private alertText: string;
  private required = required;
  private email = email;

  private form: LoginRequest = {
    email: "",
    password: "",
    rememberMe: false,
  };

  get isEmailSended(): boolean {
    const status = emailModule.status;
    if (status) {
      this.hideEmailSendedMessage();
    }
    return status;
  }

  get error(): string {
    return authModule.error;
  }

  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  private hideEmailSendedMessage(): void {
    setTimeout(() => {
      emailModule.hide();
    }, 5000);
  }

  private forgotPassword(): void {
    this.$router.push({ name: "password-recovery" });
  }

  async login(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    this.valid = isValid;
    if (isValid) {
      this.showAlert = false;
      try {
        overlayModule.showOverlay();
        let r = await authenticate.login(this.form);
        if (r.data["needsToConfirmEmail"]) {
          snackbarModule.showSnackbar({
            message:
              "L'email deve essere confermata, accedi al tuo account di posta e controlla di aver ricevuto la mail di registrazione",
            type: "error",
          });
          setTimeout(() => snackbarModule.hideSnackbar(), 4000);
          return;
        }

        if (r.data["needsToConfirmEmailFromAdministrator"]) {
          snackbarModule.showSnackbar({
            message:
              "Il tuo account deve essere confermato da un amministratore prima di poter accedere",
            type: "error",
          });
          setTimeout(() => snackbarModule.hideSnackbar(), 4000);
          return;
        }

        authModule.login(r.data);
        if (authModule.role == CLIENT && this.$route.params["product"]) {
          const product = JSON.parse(this.$route.params["product"]);
          overlayModule.showOverlay();
          await orders.addOrder(product);
          cartModule.add(product);
        }
        this.$router.push({ path: "/" });
      } catch {
        this.showAlert = true;
      }
    }
  }
}
